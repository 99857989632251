function idNameProps(route) {
  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 10,

    id: parseInt(route.query.id) || null,
    order_by_id: route.query.order_by_id || null,

    name: route.query.name || null,
    order_by_name: route.query.order_by_name || null,
  };
}

function idNameCategoryProps(route) {
  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 10,

    id: parseInt(route.query.id) || null,
    order_by_id: route.query.order_by_id || null,

    name: route.query.name || null,
    order_by_name: route.query.order_by_name || null,

    category_id: parseInt(route.query.category_id) || null,
    order_by_category_name: route.query.order_by_category_name || null,
  };
}

function staffProps(route) {
  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 10,
    first_name: route.query.first_name || null,
    order_by_first_name: route.query.order_by_first_name || null,

    last_name: route.query.last_name || null,
    order_by_last_name: route.query.order_by_last_name || null,

    email: route.query.email || null,
    order_by_email: route.query.order_by_email || null,

    role_id: parseInt(route.query.role_id) || null,
    is_active: route.query.is_active ? parseInt(route.query.is_active) : null,
    order_by_is_active_name: route.query.order_by_is_active_name || null,
  };
}

function badgeImagesProps(route) {
  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 10,

    id: parseInt(route.query.id) || null,
    order_by_id: route.query.order_by_id || null,

    type_id: parseInt(route.query.type_id) || null,
    order_by_type_name: route.query.order_by_type_name || null,

    badge_id: parseInt(route.query.badge_id) || null,
    order_by_badge_name: route.query.order_by_badge_name || null,
  };
}

export { idNameProps, idNameCategoryProps, staffProps, badgeImagesProps };
