import { REFERRAL_CODES_USAGE } from "@/rbac/links.js";

function props(route) {
  let orderByDefault = route.query.order_by_created_at || "desc";

  if (
    route.query.order_by_username ||
    route.query.order_by_referring_username ||
    route.query.order_by_referral_code ||
    route.query.order_by_family_full_name ||
    route.query.order_by_referring_family_full_name ||
    route.query.order_by_email ||
    route.query.order_by_referring_email ||
    route.query.order_by_page_name ||
    route.query.order_by_badge_name ||
    route.query.order_by_updated_at
  ) {
    orderByDefault = null;
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 20,

    username: route.query.username || null,
    order_by_username: route.query.order_by_username || null,

    referring_username: route.query.referring_username || null,
    order_by_referring_username:
      route.query.order_by_referring_username || null,

    referral_code: route.query.referral_code || null,
    order_by_referral_code: route.query.order_by_referral_code || null,

    family_full_name: route.query.family_full_name || null,
    order_by_family_full_name: route.query.order_by_family_full_name || null,

    referring_family_full_name: route.query.referring_family_full_name || null,
    order_by_referring_family_full_name:
      route.query.order_by_referring_family_full_name || null,

    email: route.query.email || null,
    order_by_email: route.query.order_by_email || null,

    referring_email: route.query.referring_email || null,
    order_by_referring_email: route.query.order_by_referring_email || null,

    page_id: parseInt(route.query.page_id) || null,
    order_by_page_name: route.query.order_by_page_name || null,

    badge_id: parseInt(route.query.badge_id) || null,
    order_by_badge_name: route.query.order_by_badge_name || null,

    order_by_created_at: orderByDefault,
    order_by_updated_at: route.query.order_by_updated_at || null,
  };
}

const referralCodeUsageRoutes = [
  {
    path: "/referral-code-usage",
    name: "ReferralCodeUsageIndex",
    meta: { title: "Referral Codes Usage", permission: REFERRAL_CODES_USAGE },
    props: (route) => props(route),
    component: () =>
      import(
        /* webpackChunkName: "referral-code-usage-index" */ "@/views/referral-code-usage/Index.vue"
      ),
  },
];

export default referralCodeUsageRoutes;
