import {
  MANAGE_FAMILIES,
  MY_FAMILIES,
  CREATE_FAMILY,
  LIMBO_FAMILIES,
  EXPORT_FAMILIES,
  VIEW_FAMILY_PROFILE,
  EDIT_FAMILY_PROFILE,
  CHANGE_FAMILY_PASSWORD,
  ADD_NEW_CLIENT,
  CLIENTS_LIST,
  EMAIL_ARCHIVE,
  PAGE_SETTINGS,
  BILLING_INFORMATION,
  COMPLETED_LIMBO_STEPS,
} from "@/rbac/links.js";
import { VIEW_EMAIL_LOG } from "@/rbac/permissions.js";

function props(route) {
  let orderByDefault = route.query.order_by_created_at || "desc";
  if (
    route.query.order_by_first_name ||
    route.query.order_by_last_name ||
    route.query.order_by_username ||
    route.query.order_by_email ||
    route.query.order_by_phone ||
    route.query.order_by_chapter_name ||
    route.query.order_by_family_status_name ||
    route.query.order_by_badge_name
  ) {
    orderByDefault = null;
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 10,

    first_name: route.query.first_name || null,
    order_by_first_name: route.query.order_by_first_name || null,

    last_name: route.query.last_name || null,
    order_by_last_name: route.query.order_by_last_name || null,

    username: route.query.username || null,
    order_by_username: route.query.order_by_username || null,

    email: route.query.email || null,
    order_by_email: route.query.order_by_email || null,

    phone: route.query.phone || null,
    order_by_phone: route.query.order_by_phone || null,

    coach_id: parseInt(route.query.coach_id) || null,
    evaluator_id: parseInt(route.query.evaluator_id) || null,

    chapter_id: parseInt(route.query.chapter_id) || null,
    order_by_chapter_name: route.query.order_by_chapter_name || null,

    badge_id: parseInt(route.query.badge_id) || null,
    order_by_badge_name: route.query.order_by_badge_name || null,

    family_status_id: parseInt(route.query.family_status_id) || null,
    order_by_family_status_name:
      route.query.order_by_family_status_name || null,

    order_by_created_at: orderByDefault,
  };
}

function limboProps(route) {
  let orderByDefault = route.query.order_by_user_id || "desc";

  if (
    route.query.order_by_first_name ||
    route.query.order_by_last_name ||
    route.query.order_by_username ||
    route.query.order_by_email ||
    route.query.order_by_created_at ||
    route.query.order_by_is_archived_name
  ) {
    orderByDefault = null;
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 10,
    order_by_user_id: orderByDefault,

    first_name: route.query.first_name || null,
    order_by_first_name: route.query.order_by_first_name || null,

    last_name: route.query.last_name || null,
    order_by_last_name: route.query.order_by_last_name || null,

    username: route.query.username || null,
    order_by_username: route.query.order_by_username || null,

    email: route.query.email || null,
    order_by_email: route.query.order_by_email || null,

    application_form_country_id:
      parseInt(route.query.application_form_country_id) || null,

    is_archived: route.query.is_archived
      ? parseInt(route.query.is_archived)
      : null,
    order_by_is_archived_name: route.query.order_by_is_archived_name || null,

    order_by_created_at: route.query.order_by_created_at || null,
  };
}

const familyRoutes = [
  {
    path: "/families",
    name: "FamilyLayoutFullWidth",
    component: () =>
      import(
        /* webpackChunkName: "family-layout-full-width" */ "@/views/layouts/FullWidth.vue"
      ),
    children: [
      {
        path: "",
        name: "FamilyIndex",
        meta: { title: "Manage Families", permission: MANAGE_FAMILIES },
        props: (route) => props(route),
        component: () =>
          import(
            /* webpackChunkName: "family-index" */ "@/views/family/Index.vue"
          ),
      },
      {
        path: "create",
        name: "FamilyCreate",
        meta: { title: "Create Family", permission: CREATE_FAMILY },
        component: () =>
          import(
            /* webpackChunkName: "family-create" */ "@/views/family/Create.vue"
          ),
      },
      {
        path: "export",
        name: "FamilyExport",
        meta: { title: "Export Family", permission: EXPORT_FAMILIES },
        component: () =>
          import(
            /* webpackChunkName: "family-export" */ "@/views/family/Export.vue"
          ),
      },
    ],
  },
  {
    path: "/my-families",
    name: "MyFamilyIndex",
    meta: { title: "My Families", permission: MY_FAMILIES },
    props: (route) => props(route),
    component: () =>
      import(
        /* webpackChunkName: "my-family-index" */ "@/views/family/MyFamilies.vue"
      ),
  },
  {
    path: "/limbo-families",
    name: "LimboIndex",
    meta: { title: "Limbo Families", permission: LIMBO_FAMILIES },
    props: (route) => limboProps(route),
    component: () =>
      import(/* webpackChunkName: "limbo-index" */ "@/views/limbo/Index.vue"),
  },
  {
    path: "/families/:id",
    name: "FamilyLayout",
    props: (route) => ({
      id: parseInt(route.params.id),
    }),
    component: () =>
      import(
        /* webpackChunkName: "family-layout" */ "@/views/family/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "FamilyView",
        meta: { title: "View Family", permission: VIEW_FAMILY_PROFILE },
        component: () =>
          import(
            /* webpackChunkName: "family-view" */ "@/views/family/View.vue"
          ),
      },
      {
        path: "edit",
        name: "FamilyEdit",
        meta: { title: "Edit Family", permission: EDIT_FAMILY_PROFILE },
        component: () =>
          import(
            /* webpackChunkName: "family-edit" */ "@/views/family/Edit.vue"
          ),
      },
      {
        path: "change-password",
        name: "FamilyChangePassword",
        meta: { title: "Change Password", permission: CHANGE_FAMILY_PASSWORD },
        component: () =>
          import(
            /* webpackChunkName: "family-change-password" */ "@/views/family/ChangePassword.vue"
          ),
      },
      {
        path: "clients/create",
        name: "FamilyClientCreate",
        meta: { title: "Add new Client", permission: ADD_NEW_CLIENT },
        component: () =>
          import(
            /* webpackChunkName: "family-client-create" */ "@/views/family/client/Create.vue"
          ),
      },
      {
        path: "clients",
        name: "FamilyClientIndex",
        meta: { title: "Clients List", permission: CLIENTS_LIST },
        component: () =>
          import(
            /* webpackChunkName: "family-client-index" */ "@/views/family/client/Index.vue"
          ),
      },
      {
        path: "page-settings",
        name: "FamilyPageSettings",
        meta: { title: "Page Settings", permission: PAGE_SETTINGS },
        component: () =>
          import(
            /* webpackChunkName: "family-page-settings" */ "@/views/family/PageSettings.vue"
          ),
      },
      {
        path: "limbo-steps",
        name: "FamilyLimboSteps",
        meta: {
          title: "Completed Limbo Steps",
          permission: COMPLETED_LIMBO_STEPS,
        },
        component: () =>
          import(
            /* webpackChunkName: "family-limbo-steps" */ "@/views/family/LimboSteps.vue"
          ),
      },
      {
        path: "billing",
        name: "FamilyBilling",
        meta: { title: "Billing", permission: BILLING_INFORMATION },
        component: () =>
          import(
            /* webpackChunkName: "family-billing" */ "@/views/family/Billing.vue"
          ),
      },
      {
        path: "email-archives",
        name: "FamilyEmailLogIndex",
        meta: { title: "Email Archive", permission: EMAIL_ARCHIVE },
        props: (route) => ({
          page: parseInt(route.query.page) || 1,
          limit: parseInt(route.query.limit) || 10,

          from: route.query.from || null,
          to: route.query.to || null,
          subject: route.query.subject || null,
        }),
        component: () =>
          import(
            /* webpackChunkName: "family-email-log-index" */ "@/views/family/email-log/Index.vue"
          ),
      },
      {
        path: "email-archives/:emailLogId/view",
        name: "FamilyEmailLogView",
        meta: { title: "View Email Archive", permission: VIEW_EMAIL_LOG },
        props: (route) => ({
          emailLogId: parseInt(route.params.emailLogId),
        }),
        component: () =>
          import(
            /* webpackChunkName: "family-email-log-view" */ "@/views/family/email-log/View.vue"
          ),
      },
    ],
  },
];

export default familyRoutes;
